<template>
  <div class="textBox left">
    {{content}}
  </div>
</template>

<script>
export default {
  name: 'titleBar',
  props: {
    content: String,
    textAlignment: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.textBox{
  display: flex;
   border-bottom:$border;
  border-right:$border;
  font-size: 30px;
  height: 4rem;
  padding-left: 10px;
  align-items: center;
  color: $backgroundColor;
  background-color: $primaryColor;
  width: auto;
  position: -webkit-sticky;
  font-variation-settings: "wght" 400;
  font-size: 2rem;
  //top: 0px;
}
.left{
  text-align: left;
}
.center{
  text-align: center;
}
</style>
