<template>
<div class="cv">
  <titleBar id="general" content="General" textAlignment="left"/>
  <div class="part">
    <table>
      <tr>
      <th>Name:</th>
      <td>{{cv.general.firstname}}</td>
      <td>{{cv.general.surname}}</td>
      </tr>
      <tr>
      <th>Date of Birth:</th>
      <td>{{cv.general.dateOfBirth}}</td>
      </tr>
      <tr>
      <th>Email:</th>
      <td>{{cv.general.email}}</td>
      </tr>
      <tr>
      <th>Phone:</th>
      <td>{{cv.general.phone}}</td>
      </tr>
    </table>
    </div>
   <titleBar id="languages" content="Languages" textAlignment="left"/>
    <div class="part">
   <table>
     <tr v-for="languages in cv.languages" v-bind:key="languages.language">
       <th>{{languages.language}}:</th>
       <td>{{languages.experience}}</td>
     </tr>
   </table>
   </div>
    <titleBar id="education" content="Education" textAlignment="left"/>
    <div class="part">
      <table>
        <tr v-for="education in cv.education" v-bind:key="education.title ">
          <th>{{education.timeframe}}:</th>
          <td>{{education.title}}</td>
        </tr>
      </table>
    </div>
    
    <titleBar id="jobs" content="Jobs" textAlignment="left"/>
    <div class="part">
      <table>
        <tr v-for="jobs in cv.jobs" v-bind:key="jobs.title">
          <th>{{jobs.timeframe}}:</th>
          <td>{{jobs.title}}</td>
        </tr>
      </table>
    </div>
    <titleBar id="formations" content="Formations" textAlignment="left"/>
        <div class="part">
        <table>
          <tr v-for="formations in cv.formations" v-bind:key="formations.title">
          <th class="link">{{formations.title}}:</th>
          <td>{{formations.timeframe}}</td>

          </tr>
        </table>
    </div>
  </div>
  
</template>

<script>
import store from "@/store.js";
import titleBar from "@/components/titleBar.vue"

export default {
  name: 'cv',
  props: {
    msg: String
  },
  components:{
    titleBar: titleBar,
  },
    data(){
    return {
      cv: store.content.cv,
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.cv{
  grid-area: content;
  overflow-y: scroll;
}
.part{
  // display: flex;
  // flex-wrap: wrap;
  padding: $padding;
}
th{
 width: 15vw;
 font-variation-settings: "wght" 600;
  font-size: 1rem;

}
table{
  border-spacing: $padding;;
}
</style>
